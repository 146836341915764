<template>
  <form-view
    :url="`/dispatch/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :before-submit="beforeSubmit"
    :custom-validator="customValidator"
  >
    <div class="section-title-row">
      <div class="title">基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="16">
        <el-form-item label-width="82px" label="工地名称" prop="siteId">
          <el-select filterable v-model="dataForm.siteId" @change="handleSiteChange">
            <el-option v-for="b in siteList" :key="b.id" :label="b.siteName" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="施工部位" prop="constructionPart">
          <el-input v-model="dataForm.constructionPart"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-form-item label-width="82px" label="工地地址" prop="siteAddress">
          <el-input disabled v-model="dataForm.siteAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="所属基地" prop="baseName">
          <el-input disabled v-model="dataForm.baseName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="执行基地" prop="executeBaseId">
          <el-select v-model="dataForm.executeBaseId">
            <el-option v-for="b in baseList" :key="b.id" :label="b.name" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label-width="82px"  label="联系人名称" prop="siteHeadId">
          <el-select filterable v-model="dataForm.siteHeadId" @change="handleSiteHeadChange">
            <el-option v-for="b in siteHeadList" :key="b.id" :label="b.userPhone.concat('  ').concat(b.userName)" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label-width="82px" label="联系人电话" prop="siteHeadPhone">
          <el-input disabled v-model="dataForm.siteHeadPhone"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="6">
        <el-form-item label-width="82px" label="项目部电话" prop="departmentPhone">
          <el-input disabled v-model="dataForm.departmentPhone"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="6">
        <el-form-item label="施工单位" prop="constructionUnit">
          <el-input disabled v-model="dataForm.constructionUnit"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="拌台" prop="mixingTable">
          <el-input v-model="dataForm.mixingTable"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label-width="82px" label="施工时间" prop="dispatchTime">
          <el-date-picker
            type="datetime"
            v-model="dataForm.dispatchTime"
            value-format="yyyy-MM-dd H:m:s"
            default-time="12:00:00"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label-width="82px" label="级配" prop="gradation">
          <el-input v-model="dataForm.gradation"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="预计方量" prop="quantityNumber">
          <el-input type="number" v-model="dataForm.quantityNumber"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="6">
        <el-form-item label="所属公司" prop="dispatcherUserId">
          <el-select filterable v-model="dataForm.dispatcherUserId">
            <el-option
              v-for="c in companyList"
              :key="c.companyName"
              :label="c.companyName"
              :value="c.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col> -->
    </el-row>
    <div class="section-title-row">
      <div class="title">危险源信息</div>
      <div class="line"></div>
    </div>
    <el-row class="flex-level">
      <el-checkbox-group v-model="dataForm.dangers">
        <el-checkbox v-for="d in dangerList" :key="d.dictId" :label="d.dictId">{{d.dictName}}</el-checkbox>
      </el-checkbox-group>
      <el-input v-model="dataForm.hazardOtherRemarks" style="width: 180px;margin-left: 20px;"></el-input>
    </el-row>
    <image-uploader
      @add="addImage"
      @remove="removeImage"
      :images="images || []"
      title="危险源现场图"
      show-create-date
    ></image-uploader>
    <div class="section-title-row">
      <div class="title">车辆信息</div>
      <div class="line"></div>
    </div>
    <el-row>
      <el-col class="flex-row" v-for="(d, i) in dataForm.carList" :key="i" :md="8">
        <label>需要车辆类型</label>
        <el-select v-model="d.demandCarSize">
          <el-option v-for="s in carSizeList" :key="s" :label="s" :value="s"></el-option>
        </el-select>
        <!-- <label>车辆自编号</label>
        <el-input v-model="d.number" style="width:150px" readonly @click.native="openDrawer(i)"></el-input>
        <label>车辆车长</label>
        <el-input v-model="d.carNickName" style="width:150px" readonly @click.native="openDrawer(i)"></el-input>
        <label>实际车辆类型</label>
        <el-input v-model="d.carSize" style="width:100px" readonly @click.native="openDrawer(i)"></el-input> -->
        <i class="icon el-icon-circle-plus" @click="insertCar(i)"></i>
        <i class="icon el-icon-delete-solid" @click="removeCar(i)" v-if="dataForm.carList.length > 1"></i>
      </el-col>
    </el-row>
    <dispatch-car
      :visible="visible"
      :data-form="dataForm"
      :site-list="siteList"
      :base-list="baseList"
      :site-head-list="siteHeadList"
      :dispatcher-user-list="dispatcherUserList"
      :car-size-list="carSizeList"
      :demand-car-size="demandCarSize"
      @close="visible = false"
      @select="setSelectCar"
    ></dispatch-car>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import DispatchCar from '@/components/dispatch-car'

export default {
  name: 'dispatch-record-update',

  components: { FormView, ImageUploader, DispatchCar },

  data () {
    return {
      loading: false,
      dataForm: {},
      visible: false,
      siteList: [],
      baseList: [],
      dangerList: [],
      siteHeadList: [],
      dispatcherUserList: [],
      companyList: [],
      numberCarList: [],
      carSizeList: [],
      demandCarSize: '',
      demandCarSizeIndex: 0,
      images: []
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          if (this.dataForm.dangers) {
            this.dataForm.dangers = this.dataForm.dangers.split(',').map(d => parseInt(d))
          } else {
            this.dataForm.dangers = []
          }
          this.getSiteHeadList(this.dataForm.siteId)
          this.images = this.dataForm.dangersImageList.map(i => ({
            id: i.id,
            url: '/files' + i.url,
            createTime: i.createTime
          }))
          this.dataForm.dangersImageList = this.dataForm.dangersImageList.map(i => i.url)
          this.loading = false
        }
      } else {
        this.dataForm = {
          siteId: '',
          constructionPart: '',
          siteAddress: '',
          baseName: '',
          executeBaseId: '',
          siteHeadId: '',
          siteHeadPhone: '',
          departmentPhone: '',
          constructionUnit: '',
          mixingTable: '',
          dispatchTime: '',
          gradation: '',
          quantityNumber: '',
          dispatcherUserId: '',
          dangers: [],
          hazardOtherRemarks: '',
          dangersImageList: [],
          carList: [{
            demandCarSize: '',
            carId: '',
            carNickName: '',
            carSize: ''
          }]
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/user/base/list'),
        method: 'post'
      })
      this.baseList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/site/list'),
        method: 'post',
        data: {}
      })
      this.siteList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/car/list'),
        method: 'post',
        data: {}
      })
      this.numberCarList = data.datas

      data = await this.$http({
        url: this.$http.adornUrl('/car/listCarSize'),
        method: 'post'
      })
      this.carSizeList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/site/list/dangers'),
        method: 'post'
      })
      this.dangerList = data.datas
      // 二级调度
      data = await this.$http({
        url: this.$http.adornUrl('/company/list'),
        method: 'post',
        data: {}
      })
      this.companyList = data.datas
    },

    addImage (path) {
      this.dataForm.dangersImageList.push(path)
    },

    removeImage (url) {
      this.dataForm.dangersImageList.splice(this.dataForm.dangersImageList.indexOf(url), 1)
    },

    insertCar (i) {
      this.dataForm.carList.splice(i + 1, 0,
        { demandCarSize: '', carId: '', carNickName: '', carSize: '' }
      )
    },

    removeCar (i) {
      this.dataForm.carList.splice(i, 1)
    },

    handleSiteChange (id) {
      this.getSiteHeadList(id)
      const item = this.siteList.find(s => s.id === id)
      this.dataForm.siteAddress = item.siteAddress
      this.dataForm.baseName = item.baseName
      this.dataForm.executeBaseId = item.baseId
      this.dataForm.departmentPhone = item.departmentPhone
      this.dataForm.constructionUnit = item.constructionUnit
      if (item.dangers) {
        this.dataForm.dangers = item.dangers.split(',').map(d => parseInt(d))
      } else {
        this.dataForm.dangers = []
      }
      this.dataForm.siteHeadId = ''
      this.dataForm.siteHeadPhone = ''
      this.getSiteImages(id)
    },

    async getSiteImages (siteId) {
      const data = await this.$http({
        url: this.$http.adornUrl('/site/getById'),
        data: siteId,
        method: 'post'
      })
      this.images = data.datas.dangersImageList.map(i => ({
        id: i.id,
        url: '/files' + i.url,
        createTime: i.createTime
      }))
      this.dataForm.dangersImageList = data.datas.dangersImageList.map(i => i.url)
      this.dataForm.mixingTable = data.datas.mixingTable
    },

    getSiteHeadList (siteId) {
      this.$http({
        url: this.$http.adornUrl('/site/list/siteHead'),
        data: siteId,
        method: 'post'
      }).then((data) => {
        this.siteHeadList = data.datas
      })
    },

    handleSiteHeadChange (id) {
      const siteHead = this.siteHeadList.find(s => s.id === id)
      this.dataForm.siteHeadPhone = siteHead.userPhone
    },
    customValidator () {
      let carList = this.dataForm.carList
      carList = carList.filter(car => { return car.demandCarSize && car.demandCarSize.trim() })
      console.log(carList.length)
      if (carList.length === 0) {
        this.$opts.alert('期望车型不能为空')
        return false
      }
      return true
    },
    beforeSubmit (params) {
      // eslint-disable-next-line no-unused-vars
      let carList = params.carList
      carList = carList.filter(car => { return car.demandCarSize && car.demandCarSize.trim() })

      const { dangers, ...others } = params
      return {
        ...others,
        dangers: dangers.join(',')
      }
    },

    openDrawer (i) {
      this.demandCarSize = this.dataForm.carList[i].demandCarSize
      this.demandCarSizeIndex = i
      this.visible = true
    },

    setSelectCar (carInfo) {
      this.visible = false
      // 车辆派遣，选择车辆
      this.dataForm.carList[this.demandCarSizeIndex].carNickName = carInfo.carNickName
      this.dataForm.carList[this.demandCarSizeIndex].carSize = carInfo.carSize
      this.dataForm.carList[this.demandCarSizeIndex].number = carInfo.number
      this.dataForm.carList[this.demandCarSizeIndex].carId = carInfo.carId
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  margin: 20px 0;
  display: flex;
  align-items: center;
  .el-select,
  .el-date-editor {
    width: 150px;
    flex: 0 0 auto;
  }
  label {
    margin: 0 10px;
    white-space: nowrap;
  }
  .el-input.remark {
    flex: 1;
    width: auto;
  }
  .icon {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    color: #666;
    &:hover {
      color: #999;
    }
  }
}
</style>
